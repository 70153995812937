import { mapActions } from 'vuex'

export default {
  data: () => ({
    collections: [],
    params: {},
    message: '',
    error: false,
    errors: {},
    loading: false,
    meta: {},
    itemSelected: {},
    itemView: {},
    model: {},
    show: false,
    showSync: false,
    showForm: false,
    showFormGenerate: false,
    modelGenerate: {},
    text: 'Carregando dados...',
    loadingProgress: false,
    textDelete: '',
    deleting: false,
    loadingGenerate: false,
    synchronizing: false,
    showFormResult: false,
    headers: [
        { text: 'Nome', value: 'name' },
        { text: 'Turno', value: 'shifts', sortable: false},
        { text: 'Campus', value: 'campuses', sortable: false},
        { text: 'Taxa', value: 'percent', sortable: false},
        // { text: 'Tipo', value: 'type.name', align: 'center', sortable: false},
        { text: 'Status', value: 'status', align: 'center', sortable: false},
        { text: 'Data / Horário', value: 'updated_at', type: 'dateTime',  align: 'center', sortable: false},
        { text: '', value: 'actions', align: 'center', sortable: false},
    ]
    }),

    watch: {
      params(params_new, params_old){
        if(params_new != params_old) {
            this.getData()
        }
      },
       //Função para tratar quando um item for exibido para deletar
      itemSelected(item_new, item_old){
          if(item_new !== item_old && Object.keys(item_new).length > 0)
          {
              this.show = true;
              this.textDelete = "Deseja excluir o item?";
          } else {
              this.show = false
              this.textDelete = '';
          }
      }, //Função para limpar a propriedade do usuário que será excluído quando usuário clica no cancelar
      show(show_new, show_old) {
          
          if(show_new != show_old && !show_new) {
              this.itemSelected = {};
          }
      },

      model(item_new, item_old){
          
        if(item_new !== item_old && this.model.id)
          {
              this.showForm = true;
              this.errors = {};
          } else if(Object.keys(this.model).length === 0){
              this.showForm = false;
              this.errors = {};
          }           
      },

      showForm(show_new, show_old) {
          if(show_new != show_old && !show_new) {
              this.model = {};
          }
      },
      showFormGenerate(show){
        if(!show){
            this.loader()
        }
      }
    },
    methods: {
      ...mapActions('dynamicGeneration', ['ActionIndexDynamicAttributions', 'ActionDeleteDynamicAttribution', 'ActionDynamicAttributionGenerate']),
        
    getData(showProgress = true){
      
        this.error = false;
        this.message = ''; 
        this.loadingProgress = showProgress;
        this.loading = true;
        this.showFormGenerate = false;
        var params = this.params;
        params.with = 'status,shifts,campuses';

        if(showProgress){
            this.showForm = false;
        }
        
        this.ActionIndexDynamicAttributions(params)
            .then((res) => {
                this.collections = res.data;
                this.meta = res.meta;
            })
            .finally(() => {
                this.loading = false;
                this.loadingProgress = false;
            })
    },

    showResults(item){
        this.itemView = item;
        this.showFormResult = true;
    },

    generate(item)
    {
        this.message = '';
        this.error = false;
        this.loadingGenerate = true;

        this.ActionDynamicAttributionGenerate({ id: item.id })
            .then((res) => {
                this.getData();
            })
            .catch((error) =>{
                this.message = error.message;
                this.error = true;
            })
            .finally(() => {
                this.loadingGenerate = false;
            });
    },


    deleteItem()
    {
        this.deleting = true;
        this.message = '';

        this.ActionDeleteDynamicAttribution({ id: this.itemSelected.id })
            .then((res) => {
                this.message = res.message;
                this.show = false;
                this.getData();
            })
            .catch((error) =>{
                this.message = error.message;
                this.error = true;
            })
            .finally(() => {
                this.deleting = false;
            });
    },

    getCampuses(teacher){

        if(teacher.campuses && teacher.campuses.length  > 0){
            
            let total = teacher.campuses.length;
            let limit = 2;
            
            if(total < limit){
                return teacher.campuses.map(o => o['abbreviation']).join(', ');
            }else{
                let items = [];

                for (let index = 0; index < limit; index++) {
                    items.push(teacher.campuses[index]);
                    
                }

                return items.map(o => o['abbreviation']).join(', ') + ' (+' + (total - limit) + ')';
            }

            
        }else{
            return "";
        }

    },
  },
  created(){
      
  }

}
