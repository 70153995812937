var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main-container',{attrs:{"icon":_vm.$route.meta.icon,"title":_vm.$route.meta.title},scopedSlots:_vm._u([{key:"tools",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"indigo darken-1","icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.showForm = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v(" Novo Registro ")])])]},proxy:true}])},[_c('emc-loader-progress',{attrs:{"show":_vm.loadingProgress}}),_c('emc-table-items',{attrs:{"headers":_vm.headers,"params":_vm.params,"collections":_vm.collections,"meta":_vm.meta,"itemDelete":_vm.itemSelected,"itemEdit":_vm.model,"loading":_vm.loading,"find":"","showAppend":""},on:{"update:params":function($event){_vm.params=$event},"update:collections":function($event){_vm.collections=$event},"update:meta":function($event){_vm.meta=$event},"update:itemDelete":function($event){_vm.itemSelected=$event},"update:item-delete":function($event){_vm.itemSelected=$event},"update:itemEdit":function($event){_vm.model=$event},"update:item-edit":function($event){_vm.model=$event}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('emc-button-icon',{attrs:{"loading":_vm.loading,"icon":"mdi-refresh","color":"success","text":"Atualizar"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.getData(false)}}})]},proxy:true},{key:"item.shifts",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.shifts.map(function (o) { return o['name']; }).join(', '))+" ")]}},{key:"item.campuses",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.getCampuses(item)))])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.campuses.map(function (o) { return o['name']; }).join(', '))+" ")])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status.name)+" "),(item.status.id == 3)?_c('span',[_c('br'),_c('v-progress-linear',{attrs:{"indeterminate":"","color":"cyan"}})],1):_vm._e()]}},{key:"item.percent",fn:function(ref){
var item = ref.item;
return [_c('v-progress-linear',{attrs:{"height":"25","color":"light-blue","striped":"","value":item.percent}},[_c('strong',[_vm._v(_vm._s(parseFloat(item.percent))+"%")])])]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('s-icon-report-download-optimized',{attrs:{"icon":"mdi-file-delimited-outline","report":"101","params":{ id: item.id },"show-message":""}}),_c('emc-table-icon-edit',{attrs:{"loading":_vm.loadingGenerate,"icon":"mdi-flash","iconColor":"amber","tool-tip":"Iniciar"},on:{"click":function($event){return _vm.generate(item)}}}),_c('emc-table-icon-edit',{attrs:{"icon":"mdi-file-eye-outline","iconColor":"primary","tool-tip":"Ver Resultado"},on:{"click":function($event){return _vm.showResults(item)}}})]}}])}),_c('sys-dynamic-attribution-register',{attrs:{"icon":_vm.$route.meta.icon,"model":_vm.model,"show":_vm.showForm},on:{"onCompleted":function($event){return _vm.getData()},"update:show":function($event){_vm.showForm=$event}}}),_c('sys-dynamic-attribution-view-result',{attrs:{"icon":_vm.$route.meta.icon,"model":_vm.itemView,"show":_vm.showFormResult},on:{"onCompleted":function($event){return _vm.getData()},"update:show":function($event){_vm.showFormResult=$event}}}),_c('emc-alert-modal',{attrs:{"text":_vm.textDelete,"disabled":_vm.deleting,"show":_vm.show},on:{"update:show":function($event){_vm.show=$event}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"color":"error","loading":_vm.deleting,"disabled":_vm.deleting},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.deleteItem()}}},[_vm._v("Excluir "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("mdi-delete")])],1)]},proxy:true}])}),_c('emc-alert-snackbar',{attrs:{"show":!! _vm.message.length,"message":_vm.message,"color":_vm.error ? 'error' : 'success'}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }